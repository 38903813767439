import React from 'react';

const Header = () => {
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar gameon-navbar navbar-expand">
                <div className="container header">
                    {/* Logo */}
                    <a className="navbar-brand" href="/">
                        <img src="/img/logo.svg" alt="Brand Logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar Nav */}
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item">
                            <a href="/" className="nav-link">Solutions</a>
                        </li>
                        <li className="nav-item">
                            <a href="/" className="nav-link">Developers</a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">Community</a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">Resources</a>
    
                        </li>
                        <li className="nav-item">
                            <a href="/contact" className="nav-link">Company</a>
                        </li>
                    </ul>
                    {/* Navbar Icons */}
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="https://www.facebook.com/hbdchain" target="_blank" className="nav-link">
                            <i class="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>
                    </ul>
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="https://www.instagram.com/hbdchain" target="_blank" className="nav-link">
                            <i class="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="https://twitter.com/hbdchain" target="_blank" className="nav-link">
                            <i class="fa-brands fa-twitter"></i>
                            </a>
                        </li>
                    </ul>
                    <ul className="navbar-nav icons">
                        <li className="nav-item">
                            <a href="https://discord.gg/RMEcXbSY" target="_blank" className="nav-link">
                            <i class="fa-brands fa-discord"></i>
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="icon-menu m-0" />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;