import React, { Component } from 'react';

const initData = {
    
}

const data = [
    {
        id: "1",
        title: "Metaverse 3D"
    },
    {
        id: "2",
        title: "ISO"
    },
    {
        id: "3",
        title: "BLOCK"
    }
]


class ProjectOne extends Component {
    state = {
        initData: {},
        data: [],
        socialData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data,
        });
    }
    render() {
        return (
            <section id="explore" className="project-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            
                        </div>
                    </div>
                    <div className="project-slides">
                        <div className="swiper-container slider-mid items">
                            <div className="swiper-wrapper">
                                {this.state.data.map((item, idx) => {
                                    return (
                                        <div key={`pd_${idx}`} className="swiper-slide item">
                                            <div className="card project-card">
                                                <div className="media">
                                                    
                                                    <div className="media-body ml-4">
                                                        
                                                            <h4 className="m-0">{ item.title }</h4>
                                                        
                                                        <div className="countdown-times">
                                                            <h6 className="my-2">Registration in:</h6>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                
                                        
                                                      {/* Social Share */}
                                                      <div className="social-share ml-auto">
                                                        <ul className="d-flex list-unstyled">
                                                            {this.state.socialData.map((item, idx) => {
                                                                return (
                                                                    <li key={`sd_${idx}`}>
                                                                        <a href="/#">
                                                                            <i className={ item.icon } />
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                        </div>

                                                    </div>
                                                    
                                                </div>
                                                {/* Blockchain Icon */}
                                                <div className="blockchain-icon">
                                                    <img src={ item.blockchain } alt="" />
                                                </div>
                                            </div>
                                    );
                                })}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ProjectOne;